<template>
  <Dialog>
    <div class="px-md pb-md flex flex-col max-h-[92vh]">
      <div v-if="$siteIdent.startsWith('minilu')" class="flex justify-center">
        <img
          src="@/assets/images/minilu/upload-verification-file.svg"
          class="relative -top-md"
        />
      </div>
      <div v-else class="flex justify-center mb-sm mt-md">
        <Headline
          headline="user.account.verficationFile.uploadSuccessDialog.headline"
          level="h4"
        />
      </div>
      <div class="h-full overflow-auto">
        <div class="text-center">
          {{ t('user.account.verficationFile.uploadSuccessDialog.text') }}
        </div>
        <BasicButton
          classes="w-full px-sm md:px-0 pt-md"
          :label="t('user.account.verficationFile.uploadSuccessDialog.close')"
          :type="EButtonType.SUBMIT"
          :btn-style="EButtonStyle.CTA"
          bg-color="var(--minilu-btn-base)"
          full-width
          @click="dialog.close()"
        ></BasicButton>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/empty/dialogFrame.vue';
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import { Headline, BasicButton } from '@/complib';
import useUploadVerificationFileSuccessDialog from '~/composables/dialogs/useUploadVerificationFileSuccessDialog';
const { t } = useTrans();

const dialog = useUploadVerificationFileSuccessDialog();
</script>
